import logo from "./img/logo.png";
import logo3 from "./img/logo3.png";
import hero1 from "./img/hero-1.png";
import hero2Title from "./img/hero-2-title.png";
import tw from "./img/tw.png";
import tg from "./img/tg.png";
import box from "./img/43edf60bc5830f2116be4e45ea3b82bf.png";
import utility from "./img/utility.png";
import winner from "./img/winner.png";
import tokenomics from "./img/tokenomics.png";
import contract from "./img/contract.png";
import total from "./img/total.png";
import renoun from "./img/renoun.png";
import burn from "./img/burn.png";
import addlp from "./img/addlp.png";
import slider1 from "./img/slider-1.png";
import slider2 from "./img/slider-2.png";
import slider3 from "./img/slider-3.png";

import AOS from "aos";
import "aos/dist/aos.css";

import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 900,
    });
    AOS.refresh();
  }, []);
  return (
    <div className="App">
      <header className="bg-[#DE0304] py-4 lg:py-2">
        <div className="container mx-auto px-2 lg:px-5 flex flex-col lg:flex-row justify-between items-center gap-5">
          <div className="w-20">
            <img src={logo} alt="" />
          </div>
          <ul className="flex gap-5 lg:gap-10 font-title text-white text-lg">
            <li>
              <a
                href="https://t.me/PenguinSOL"
                target="_blank"
                rel="noopener noreferrer"
                className="transition-all hover:opacity-75"
              >
                JOIN PENGUIN
              </a>
            </li>
            <li>
              <a href="#utility" className="transition-all hover:opacity-75">
                UTILITY
              </a>
            </li>
            <li>
              <a href="#tokenomics" className="transition-all hover:opacity-75">
                TOKENOMICS
              </a>
            </li>
          </ul>
          <div className="flex gap-5 items-center">
            <a
              href="https://t.me/PenguinSOL"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-all hover:opacity-75"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <g clip-path="url(#clip0_5_506)">
                  <path
                    d="M16.4201 0.816101C7.75597 0.816101 0.736084 7.83598 0.736084 16.5002C0.736084 25.1643 7.75597 32.1842 16.4201 32.1842C25.0843 32.1842 32.1042 25.1643 32.1042 16.5002C32.1042 7.83598 25.0843 0.816101 16.4201 0.816101ZM24.123 11.5609L21.5491 23.6908C21.3594 24.5509 20.8471 24.7596 20.1325 24.3548L16.2114 21.4647L14.3205 23.2861C14.1118 23.4947 13.9347 23.6718 13.53 23.6718L13.8082 19.6812L21.0748 13.1167C21.391 12.8384 21.0052 12.6803 20.5878 12.9586L11.6074 18.6125L7.73699 17.4045C6.89587 17.1389 6.8769 16.5634 7.91407 16.1587L23.0353 10.3277C23.7373 10.0748 24.3507 10.4985 24.123 11.5609Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5_506">
                    <rect
                      width="32.38"
                      height="32.38"
                      fill="white"
                      transform="translate(0.22998 0.309998)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              href="https://twitter.com/PenguinSolana"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-all hover:opacity-75"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="27"
                viewBox="0 0 33 27"
                fill="none"
              >
                <path
                  d="M29.6614 6.90486C29.682 7.19248 29.682 7.48017 29.682 7.7678C29.682 16.5408 23.0047 26.6493 10.8005 26.6493C7.04065 26.6493 3.54791 25.5603 0.609863 23.6702C1.14407 23.7318 1.65766 23.7523 2.21242 23.7523C5.31477 23.7523 8.17066 22.7045 10.4512 20.917C7.53374 20.8554 5.0888 18.9447 4.24642 16.3148C4.65736 16.3764 5.06825 16.4175 5.49975 16.4175C6.09555 16.4175 6.69142 16.3353 7.24612 16.1915C4.20537 15.5751 1.92473 12.9042 1.92473 9.67853V9.59638C2.80816 10.0895 3.83553 10.3977 4.92437 10.4387C3.13689 9.24703 1.96584 7.21304 1.96584 4.9119C1.96584 3.67919 2.29451 2.54918 2.86982 1.56298C6.1366 5.58992 11.047 8.21972 16.5532 8.50741C16.4505 8.01431 16.3889 7.50072 16.3889 6.98707C16.3889 3.3299 19.3474 0.350815 23.0251 0.350815C24.9358 0.350815 26.6617 1.15209 27.8739 2.44647C29.3737 2.15885 30.8119 1.60409 32.0858 0.843914C31.5926 2.38487 30.5448 3.67925 29.1683 4.50102C30.5037 4.35727 31.7981 3.98737 32.9897 3.47378C32.0859 4.78865 30.9558 5.9597 29.6614 6.90486Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </header>

      <main>
        <section>
          <img src={hero1} alt="" />
        </section>

        <section
          data-aos="fade-up"
          className="py-20 bg-[url('./img/bg-2.png')] bg-cover bg-no-repeat"
        >
          <div className="container mx-auto px-2 lg:px-5">
            <div className="max-w-xl mx-auto">
              <img src={hero2Title} alt="" />
            </div>
            <div className="max-w-xl mx-auto mt-5">
              <img src={logo3} alt="" />
            </div>

            <div className="relative mt-16 max-w-4xl mx-auto">
              <img className="hidden lg:block" src={box} alt="" />
              <div className="w-full px-0 lg:px-28 text-center text-[#2E469C] relative lg:absolute lg:left-1/2 lg:-translate-x-1/2 lg:top-1/2 lg:-translate-y-1/2">
                <h3 className="font-title text-3xl">
                  WHAT MAKES PENGUIN DIFFERENT?
                </h3>
                <p className="mt-6 text-lg">
                  Emerging from the shadows of skepticism and mistrust, Penguin
                  Token emerges as a beacon of hope for those who have lost
                  faith in the cryptocurrency realm. Witness to the devastating
                  impact of scams and fraudulent schemes, we are driven by an
                  unwavering mission: to rekindle trust and establish a secure
                  haven for investors.
                  <br />
                  Penguin Token is more than just a meme token; it's a movement,
                  a community-driven initiative that prioritizes safety,
                  transparency, and unwavering support. We believe that the
                  cryptocurrency space deserves better, a place where integrity
                  and ethical practices reign supreme.
                </p>
                <div className="mt-8 flex flex-col lg:flex-row justify-center gap-5 items-center">
                  <a
                    href="https://t.me/PenguinSOL"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="max-w-[250px] transition-all hover:opacity-75"
                  >
                    <img src={tg} alt="" />
                  </a>
                  <a
                    href="https://twitter.com/PenguinSolana"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="max-w-[250px] transition-all hover:opacity-75"
                  >
                    <img src={tw} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="utility"
          data-aos="fade-up"
          className="py-20 bg-[url('./img/bg-3.png')] bg-cover bg-no-repeat"
        >
          <div className="container mx-auto px-2 lg:px-5">
            <div className="max-w-xl mx-auto">
              <img src={utility} alt="" />
            </div>

            <div className="relative mt-16 max-w-4xl mx-auto">
              {/* <img src={box} alt="" /> */}
              <div className="w-full px-0 lg:px-28 text-center text-[#2E469C] bg-white rounded-2xl">
                <p className="mt-6 text-lg py-8 px-5">
                  Penguin-Penguin has invented a Telegram bot that selects
                  holders at specific times for a gift! These will be collected
                  and distributed during our daily voice chats.
                </p>
              </div>
            </div>

            <div className="max-w-md mx-auto mt-10">
              <img src={winner} alt="" />
            </div>
          </div>
        </section>

        <section
          id="tokenomics"
          data-aos="fade-up"
          className="py-20 bg-[url('./img/bg-4.png')] bg-cover bg-no-repeat"
        >
          <div className="container mx-auto px-2 lg:px-5">
            <div className="max-w-xl mx-auto">
              <img src={tokenomics} alt="" />
            </div>

            <div className="relative mt-16 max-w-4xl mx-auto">
              <img src={contract} alt="" />
              <span className="flex text-lg mt-1 font-title absolute text-white top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2">
                0x000000000000000000000000
              </span>
            </div>

            <div className="max-w-4xl mx-auto grid grid-cols-2 gap-5 mt-10">
              <div data-aos="fade-right">
                <img src={total} alt="" />
              </div>
              <div data-aos="fade-left">
                <img src={addlp} alt="" />
              </div>
              <div data-aos="fade-right">
                <img src={burn} alt="" />
              </div>
              <div data-aos="fade-left">
                <img src={renoun} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="py-5">
          <div className="container mx-auto grid grid-cols-1 lg:grid-cols-3 gap-5">
            <div>
              <img src={slider1} alt="" />
            </div>
            <div>
              <img src={slider2} alt="" />
            </div>
            <div>
              <img src={slider3} alt="" />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
